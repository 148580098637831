<template>
  <div
    style="overflow-y: scroll;"
  >  
  <div class="top_bg_"   :style="{ backgroundImage: 'url(' + 'img/cpRank/bg.png' + ')' }">
    <back-nav :icon="'img/backw.png'" :textColor="'#fff'" :backColor="'none'" />
    <div class="rank_btn" style="background-image: url('img/cpRank/rank_btn.png')" @click="toRank"></div>
    <div
      class="top_one_box"
      style="background-image: url('img/cpRank/rank_bg.png')"
      v-if="list.length>0"
    >
      <div class="face_box">
        <div class="big_face">
          <img :src="list[0].a_head_portrait" alt="" />
        </div>
        <img
          class="icon"
          src="img/cpRank/aixin.png"
          alt=""
        />
        <div class="big_face">
          <img :src="list[0].b_head_portrait" alt="" />
        </div>
      </div>
      <div class="nickname_box">
        <div
          class="nickname"
          style="background-image: url('img/cpRank/nickname_bg.png')"
        >
          {{ list[0].a_nickname }}
        </div>
        <div
          class="nickname"
          style="background-image: url('img/cpRank/nickname_bg.png')"
        >
          {{ list[0].b_nickname }}
        </div>
      </div>
      <div
        class="value_box"
        style="background-image: url('img/cpRank/value_bg.png')"
      >
        {{ list[0].intimacy_value }}
      </div>
    </div>
  </div>
 
    <!-- <div v-if="nodata" class="noData">
        <img :src="'img/noData.png'" alt="" />
      </div> -->
    <div class="list" style="background-color: #6B48DA" v-if="list2.length>0">
      <div
        class="table"
        style="background-image: url('img/cpRank/table.png')"
      ></div>
      <div class="list_scroll">
        <div
          class="item"
          style="background-image: url('img/cpRank/item_bg.png')"
          v-for="(item, index) in list2"
          :key="index"
        >
          <div class="index">{{ index + 2 }}</div>
          <div class="contain" style="display: flex;flex-direction: column;justify-content: center;">
            <div class="face_box_small_">
              <div class="small_face">
                <img :src="item.a_head_portrait" alt="" />
              </div>
              <img
                class="icon_small"
                src="img/cpRank/aixin.png"
                alt=""
              />
              <div class="small_face">
                <img :src="item.b_head_portrait" alt="" />
              </div>
            </div>
            <div class="nickname_box_small">
              <div class="nickname_small">{{ item.a_nickname }}</div>
              <div class="nickname_small">{{ item.b_nickname }}</div>
            </div>
          </div>
          <div class="value_text">{{item.intimacy_value}}</div>
        </div>
        <van-overlay :show="loading">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
            "
          >
            <van-loading />
            <div style="color: #ffffff">数据加载中...</div>
          </div>
        </van-overlay>
      </div>
    </div>
  </div>
</template>
  
  <script>
import "@/assets/css/cpRank.css";
import BackNav from "@/components/backNav.vue";
import { myNetRank } from "@/api/relationNet";
export default {
  name: "room",
  components: {
    BackNav,
  },
  data() {
    return {
      loading: false,
      nodata: false,
      page: 1,
      list: [],
      list2: [],
    };
  },
  created() {
    let access_token = this.$route.query.access_token;
    localStorage.setItem("access_token", access_token);
    this.getData();
  },
  methods: {
    getData() {
      let Params = {
        page: 1,
        rows: 15,
        type:1
      };
      this.loading = true;
      myNetRank(Params)
        .then(
          (response) => {
            this.loading = false;
            if (response.data.length == 0) {
              this.nodata = true;
            }
            this.list = response.data
            this.list2 = this.list.slice(1);
          },
          (error) => {
            this.$toast(error);
          }
        )
        .catch((error) => {
          this.$toast(error);
        });
    },
    toRank(){
      this.$router.push({path:'/rank/cpRankInfo'})
    },
    closePage() {
      let params = "调用";
      this.$bridge.callhandler("closePage", params, (data) => {
        // 处理返回数据
      });
    },
    checkUser(id) {
      let param = id.toString();
      this.$bridge.callhandler("checkUser", param, (data) => {
        // 处理返回数据
      });
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->